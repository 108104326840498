import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function purchasereturnboundUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'returnbound_no', label: '采购退货单编号', sortable: true },
    { key: 'inbound_no', label: '入库单编号', sortable: true },
    { key: 'order_no', label: '采购单编号', sortable: true },

    // { key: 'is_refund', label: '是否退款', sortable: true },
    { key: 'supplier_name', label: '供应商', sortable: true },
    { key: 'reason', label: '退货原因', sortable: true },
   /* { key: 'supplier_contact_name', label: '供应商联系人', sortable: true },
    { key: 'supplier_contact_mobile', label: '供应商联系方式', sortable: true },*/
    // { key: 'return_time', label: '退货日期', sortable: true },
    /*{ key: 'responsibility', label: '采购退货费用承担方 purchase_returnbound_responsibility', sortable: true },
    { key: 'delivery_method', label: '物流方式  delivery_method_purchase_returnbound', sortable: true },
    { key: 'purchaser_id', label: '采购负责人ID', sortable: true },
    { key: 'purchaser_name', label: '采购负责人', sortable: true },
    { key: 'purchaser_mobile', label: '采购负责人联系方式', sortable: true },*/
    // { key: 'refund_time', label: '退款日期', sortable: true },
    { key: 'qty', label: '退货数量', sortable: true },
    { key: 'trueqty', label: '实际退货数量', sortable: true },
    { key: 'subtotal', label: '应退金额', sortable: true },
    { key: 'truesubtotal', label: '实退金额', sortable: true },
    { key: 'remark', label: '备注', sortable: true },
    // { key: 'attachments', label: '附件', sortable: true },
    // { key: 'add_time', label: '创建时间', sortable: true },
    { key: 'creator', label: '申请人', sortable: true },
    { key: 'status', label: '审核状态', sortable: true },
    /*{ key: 'modify_time', label: '修改时间', sortable: true },
    { key: 'updator', label: '修改人', sortable: true },*/
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(50)
  const limitOptions = [25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('returnbound_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = store.getters['purchasereturnbound/getCondition']
    const params = {
      search_key: searchKey.value,
      start: start.value,
      limit: limit.value,
      order_by: orderBy.value,
      order_desc: isSortDirDesc.value === true ? 'desc':'',
    }
    Object.assign(params,condition)
    store
      .dispatch('purchasereturnbound/search', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
