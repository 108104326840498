<template>
  <b-card style="width:100%">
    <b-form
        @submit.prevent="refreshTable"
        @reset.prevent="resetCondition"
    >
      <b-row>
        <b-col v-for="(value,name,index) in page" :key="index" md="3">
          <xy-form :prop-data="value" v-model="condition[name]"></xy-form>
        </b-col>

        <b-col
            cols="12"
            md="6"
        >
          <b-button
              variant="outline-primary"
              type="submit"
              class="mr-1"
          >
            <span class="align-right">查询</span>
          </b-button>

          <b-button
              variant="outline-primary"
              type="reset"
          >
            <span class="align-right">重置</span>
          </b-button>

        </b-col>
        <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
        >

          <company-select
              ref="companySelect" :companyType="companyType" v-if="modalName === '签约主体' || modalName === '供应商' "
          />

          <user-select
              ref="creatorSelect" :department-id="1" v-else-if="'申请人' === modalName "
          />

        </b-modal>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import { getCodeOptions } from '@core/utils/filter'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import CompanySelect from '@/views/apps/company/CompanySelect'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import XyDatePicker from '@/views/components/xy/XyDatePicker'
import purchasereturnboundStore from '@/views/apps/purchasereturnbound/purchasereturnboundStore'
import XyForm from '@/views/components/xy/XyForm'

export default {
  components: { XyForm, XyDatePicker, UserSelect, CompanySelect },
  props: {},
  setup(props, { emit }) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchasereturnbound')) store.registerModule('purchasereturnbound', purchasereturnboundStore)

    onMounted(() => {
      state.condition = store.getters['purchasereturnbound/getCondition']
    })

    const state = reactive({
      condition: {
        supplierName: undefined,
        creatorName: undefined,
      },
      companyType: '',
      modalName: '',
      singleModal:undefined,
      page:{
        returnbound_no: {
          type: 'input',
          attrs: {
            id: 'returnbound_no',
            label: '申请编号',
          },
        },
        reason: {
          type: 'select',
          attrs: {
            id: 'reason',
            label: '退货原因',
            optionsType: 'purchase_returnbound_reason',
          },
        },
        is_refund: {
          type: 'select',
          attrs: {
            id: 'is_refund',
            label: '是否退款',
            disabled: computed(() => state.is_refund_disabled),
            optionsType: 'yesno',
          },
        },
        supplierName: {
          type: 'input',
          attrs: {
            id: 'supplierName',
            label: '供应商',
            readonly: true,
            placeholder: '点击搜索供应商',
          },
          on: {
            click: () => methods.showSingleModal('供应商')
          }
        },
        order_no: {
          type: 'input',
          attrs: {
            id: 'order_no',
            label: '订单编号',
          },
        },
        product_name: {
          type: 'input',
          attrs: {
            id: 'product_name',
            label: '产品名称',
          },
        },
        creatorName: {
          type: 'input',
          attrs: {
            id: 'creatorName',
            label: '申请人',
            readonly: true,
            placeholder: '点击搜索申请人',
          },
          on: {
            click: () => methods.showSingleModal('申请人')
          }
        },
        status: {
          type: 'select',
          attrs: {
            id: 'status',
            label: '审核状态',
            optionsType: 'purchase_returnbound_status',
          },
        },
      },
    })
    const methods = {
      showSingleModal(modalName) {
        if (modalName === '签约主体') {
          state.companyType = 'Group'
        } else if (modalName === '供应商') {
          state.companyType = 'Supplier'
        }

        state.modalName = modalName
        state.singleModal.show()
      },
      onSelectSingle() {
        let data = {}
        switch (state.modalName) {
          case '签约主体': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.company_id = data[0].company_id
              state.condition.companyName = data[0].company_name
            }
            break
          }
          case '供应商': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.supplier_id = data[0].company_id
              state.condition.supplierName = data[0].company_name
            }
            break
          }
          case '申请人': {
            data = this.$refs.creatorSelect.getSelected()
            if (data.length > 0) {
              state.condition.creator = data[0].user_id
              state.condition.creatorName = data[0].full_name
            }
            break
          }
        }
      },
      refreshTable() {
        let time = state.condition.time
        if (time && !time.includes('至')) {
          toast.error("请选择范围日期")
          return;
        }
        emit('refreshTable')
      },
      resetCondition() {
        state.condition = {
          supplierName: undefined,
          creatorName: undefined,
        }
        store.commit('purchasereturnbound/updateCondition', state.condition)
        methods.refreshTable()
      },
    }
    return {
      ...toRefs(state),
      ...methods,
      //filter
      getCodeOptions
    }
  },
}
</script>

<style scoped>

</style>
